@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
body {
  --q-dark-blur: #{$dark-blur};
}
.bg-dark-blur {
  background-color: var(--q-dark-blur) !important;
  backdrop-filter: blur(6px);
}
.text-dark-blur {
  background-color: var(--q-dark-blur);
}
